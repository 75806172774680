import { environment } from "environments/environment";
import { NavigationItemModel } from "../models/navigation-item.model";
import { Constants } from "./constants";

export const navigationItems: NavigationItemModel[] = [
  {
    id: 'client_portal',
    title: 'client_portal',
    titleToTranslate: true,
    type: 'basic',
    link: `${environment.ENDPOINTS.SPARTAN_URL}`,
    moduleCodeName: 'home',
    privilege: 'public-only',
    mobileOnly: false,
    icon: 'business',
  },
  {
    id: 'find_job',
    title: 'find_job',
    titleToTranslate: true,
    type: 'basic',
    link: `/${Constants.SEARCH_ROUTE}`,
    moduleCodeName: 'home',
    privilege: 'public',
    icon: 'search',
  },
  // {
  //   id: 'explore_companies',
  //   title: 'explore_companies',
  //   titleToTranslate: true,
  //   type: 'basic',
  //   link: '#',
  //   moduleCodeName: 'home',
  //   privilege: 'public',
  // },
  {
    id: 'my_candidacies',
    title: 'my_candidacies',
    titleToTranslate: true,
    type: 'basic',
    link: `/${Constants.CANDIDACIES_ROUTE}`,
    moduleCodeName: 'home',
    privilege: 'private',
    icon: 'assignment',
  },
  {
    id: 'prices',
    title: 'prices',
    titleToTranslate: true,
    type: 'basic',
    link: null,
    moduleCodeName: 'home',
    privilege: 'public-only',
    mobileOnly: false,
    icon: 'attach_money',
  },
  {
    id: 'alerts',
    title: 'alerts',
    titleToTranslate: true,
    type: 'basic',
    link: `/alerts`,
    moduleCodeName: 'home',
    privilege: 'private',
    mobileOnly: false,
    icon: 'notifications',
  },
  {
    id: 'how_to_create_announcement',
    title: 'how_to_create_announcement',
    titleToTranslate: true,
    type: 'basic',
    link: `/${Constants.CREATE_JOB_OFFER_ROUTE}`,
    moduleCodeName: 'home',
    privilege: 'public-only',
    mobileOnly: false,
    icon: 'helpe',
  },
  {
    id: 'Training_Retraining',
    title: 'Training_Retraining',
    titleToTranslate: true,
    type: 'collapsable',
    link: `/${Constants.TRAINING_ROUTE}`,
    children: [
      {
        id: 'resume_help_and_advice',
        title: 'resume_help_and_advice',
        titleToTranslate: true,
        type: 'basic',
        link: `/${Constants.TRAINING_ROUTE}/${Constants.RESUME_HELP_AND_ADVICE_ROUTE}`,
        moduleCodeName: 'home',
        privilege: 'public',
        mobileOnly: false,
        icon: 'info',
      },
      {
        id: 'Switzerland_working_guide',
        title: 'Switzerland_working_guide',
        titleToTranslate: true,
        type: 'basic',
        link: `/${Constants.TRAINING_ROUTE}/${Constants.SWITZERLAND_WORKING_GUIDE_ROUTE}`,
        moduleCodeName: 'home',
        privilege: 'public',
        mobileOnly: false,
        icon: 'info',
      },
      // {
      //   id: 'insurance_advisor_Training',
      //   title: 'insurance_advisor_Training',
      //   titleToTranslate: true,
      //   type: 'basic',
      //   link: `/${Constants.TRAINING_ROUTE}/${Constants.INSURANCE_ADVISOR_TRAINING_ROUTE}`,
      //   moduleCodeName: 'home',
      //   privilege: 'public',
      //   mobileOnly: false,
      //   icon: 'info',
      // },
      {
        id: 'traiding_training_centrer',
        title: 'traiding_training_centrer',
        titleToTranslate: true,
        type: 'basic',
        link: `/${Constants.TRAINING_ROUTE}/${Constants.TRADING_TRAINING_CENTER_ROUTE}`,
        moduleCodeName: 'home',
        privilege: 'public',
        mobileOnly: false,
        icon: 'info',
      },
      {
        id: 'Office_training_WebMarketing',
        title: 'Office_training_WebMarketing',
        titleToTranslate: true,
        type: 'basic',
        link: `/${Constants.TRAINING_ROUTE}/${Constants.OFFICE_TRAINING_WEBMARKETING_ROUTE}`,
        moduleCodeName: 'home',
        privilege: 'public',
        mobileOnly: false,
        icon: 'info',
      },
    ],
    moduleCodeName: 'home',
    privilege: 'public',
    mobileOnly: false,
    icon: 'info',
  },
  {
    id: 'cv_amelioration',
    title: 'cv_amelioration',
    titleToTranslate: true,
    type: 'basic',
    link: `/${Constants.CV_AMELIORATION_ROUTE}`,
    moduleCodeName: 'home',
    privilege: 'public',
    mobileOnly: false,
    icon: 'info',
  },
  {
    id: 'contact_us',
    title: 'contact_us',
    titleToTranslate: true,
    type: 'basic',
    link: `/contact-us`,
    moduleCodeName: 'home',
    privilege: 'public',
    mobileOnly: false,
    icon: 'contact_mail',
  },
  {
    id: 'about_us',
    title: 'about_us',
    titleToTranslate: true,
    type: 'basic',
    link: `/about-us`,
    moduleCodeName: 'home',
    privilege: 'public',
    mobileOnly: false,
    icon: 'info',
  },

];


export const footerItems: NavigationItemModel[] = [
  {
    id: 'privacy_policy',
    title: 'privacy_policy_title',
    titleToTranslate: true,
    type: 'basic',
    link: `CGU`,
    privilege: 'public',
  },
  {
    id: 'politics',
    title: 'politics_title',
    titleToTranslate: true,
    type: 'basic',
    link: `PPD`,
    privilege: 'public',
  },
  // {
  //   id: 'site_map',
  //   title: 'site_map_title',
  //   titleToTranslate: true,
  //   type: 'basic',
  //   link: `#`,
  //   privilege: 'public',
  // },
];
